import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { App } from './app/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-ec-add-employee-spa',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-add-employee-spa'

//this will automatically be done by banquetSingleSpaReact at some point.
sessionStorage.setItem(
  `sentry:${name}`,
  `https://2a6418e113254a26824d9742b7376ec1@o37442.ingest.sentry.io/4505601696858112`
)
