import { Translations } from './en-US'

const translations: Translations = {
  amountTooLarge: 'La cantidad es demasiado grande',
  aboutThisEmployee: `Sobre este empleado`,
  acaPlan: `Plan de la Ley del Cuidado de Salud a Bajo Precio (ACA)`,
  actions: `Acciones`,
  add: `Agregar`,
  addEmployee: `Agregar al equipo`,
  addEmail: `+ Añadir correo electrónico`,
  addEmailOnboarding: `Agregue un correo electrónico`,
  additionalDocuments: `Documentos adicionales`,
  additionalDocumentsSubtext: `Suba documentos adicionales para la incorporación de {{name}}. Estos documentos solo se aplicarán una vez y no se utilizarán para otras nuevas contrataciones.`,
  additionalDocumentsUploadError: `Los siguientes documentos no se han podido cargar, por favor elimínelos e inténtelo de nuevo.`,
  addJob: `Añadir trabajo`,
  addTeamMember: `Añadir miembro del equipo`,
  apply: `Aplicar`,
  assignOnboardingDocument: `Asignar documentos de incorporación`,
  back: `Regresar`,
  basicInfo: `Info Básica`,
  benefitPlan: `Plan de beneficios`,
  benefits: `Beneficios`,
  bulkPayChange: `Cambio de pago masivo`,
  business: `Negocio (FEIN)`,
  businesses: `Negocios (FEIN)`,
  cancel: `Cancelar`,
  cantReviewDocsNoEmailWarning: `{{ name }} no podrá revisar estos documentos hasta que se proporcione un correo electrónico. `,
  cantReviewDocsNoEmailWarningCont: `más tarde para asegurarse de que puedan crear su cuenta y finalizar la incorporación. También puede ingresar su información usted mismo.`,
  checkOutThisArticle: `consulte este artículo.`,
  chosenName: `Nombre elegido`,
  chosenNameRegexFailed: `Nombre elegido no puede contener emojis`,
  clear: `Limpiar`,
  clearFilters: `Filtros limpiar`,
  completePayrollAccount: `Cuenta de nómina completa`,
  completePayrollAccountSubtext: `Crear inicio de sesión de nómina`,
  completePersonalInformation: `Información personal completa`,
  completePersonalInformationSubtext: `Dirección, información de contacto, etc.`,
  continue: `Continuar`,
  contractor1099: `Contratista (1099)`,
  customFields: `Campos personalizados`,
  customFieldRequired: `Este campo personalizado es obligatorio`,
  customFieldsSubtext: `La información que proporcione se mostrará en los documentos de incorporación de {{ name }}.`,
  deleteJob: `Eliminar trabajo`,
  desktopFactWelcomeHeader: `¡Bienvenido a New Hire!`,
  desktopFactWelcomeDescription: `¡Agregue a sus empleados aquí! Actualizamos este formulario para facilitarle las cosas. Para obtener más información, `,
  desktopFactRequestSsnHeader: `Requerir SSN`,
  desktopFactDontRequireSsnDescription: `Su compañía marca el SSN como opcional para los miembros del equipo en este momento. El administrador puede gestionar esto en la `,
  desktopFactRequireSsnDescription: `Actualmente, su compañía está configurada para exigir el SSN de todos los miembros del equipo antes de que se pueda crear un registro. Puedes administrar esto en `,
  desktopFactNewHireSettings: `Configuración de New Hire.`,
  desktopFactNewHireSettingsInQuotes: `"Configuración de New Hire."`,
  desktopFactTippedEmployeeHeader: `Empleados que reciben propinas`,
  desktopFactTippedEmployeeDescription: `Para empleados que reciben propinas, ingrese el salario base. Para obtener más información sobre cómo gestionar e integrar sugerencias, `,
  desktopFactEEOHeader: `Clasificación EEO-1`,
  desktopFactEEODescription: `Actualmente, su empresa está configurada para recopilar información EEO de los empleados. Si no necesita este paso, simplemente puede desactivarlo en la `,
  desktopFactEEODescriptionNonAdmin: `Actualmente, su empresa está configurada para recopilar información EEO de los empleados. Los administradores pueden administrar esto en "Configuración de nuevas contrataciones".`,
  desktopFactPermissionsHeader: `Permisos`,
  desktopFactPermissionsDescription: `Esto determina qué acceso tienen los empleados dentro del sistema de Toast Payroll. El rol con menor acceso es "Empleado". Para obtener más información sobre las funciones de seguridad, `,
  desktopFactBenefitsHeader: `Beneficios y más para los nuevos usuarios de Toast Payroll`,
  desktopFactBenefitsDescription: `Si aún no se han configurado las políticas de Tiempo fuera del trabajo y otras, seleccione "por defecto" o "TBD" o similar en el menú desplegable. Si todavía estás trabajando con tu consultor de incorporación, ponte en contacto con él para que te ayude. Para obtener más ayuda, póngase en contacto con `,
  desktopFactDocumentsHeader: `Agregar documentos de incorporación para todos los nuevos empleados`,
  desktopFactDocumentsDescription: `Los administradores pueden agregar documentos para que los futuros miembros del equipo los revisen `,
  desktopFactDocumentsHereLink: `aquí`,
  desktopFactDocumentsRest: `, de esta forma no tendrás que agregarlos cada vez.`,
  desktopFactDocumentNonAdmin: `en la "Configuración de documentación requerida"`,
  desktopFactReviewLastStepHeader: `¡Último paso!`,
  desktopFactReviewLastStepDescription: `Así es como se configurará su empleado en el momento de la nueva contratación. Al hacer clic en "Agregar al equipo" se creará su registro. Siempre puedes actualizar su información directamente en su perfil.`,
  desktopFactReviewNextHeader: `¿Qué pasa después?`,
  desktopFactReviewNextDescription: `Después de hacer clic en "Agregar al equipo" a continuación, si proporcionó una dirección de correo electrónico para su empleado, recibirá un correo electrónico de bienvenida de nuestra parte para comenzar la incorporación. Puedes revisar cuál es su experiencia de incorporación en este `,
  desktopFactReviewNextArticle: `artículo`,
  discardExit: `Descartar & salir`,
  documents: `Documentos`,
  dragAndDropText: `o arrastre y suelte el archivo aquí`,
  employmentDetails: 'Detalles de Empleo',
  taxDesignation: 'Designación fiscal',
  eeoClassification: `Categoría de trabajo EEO-1`,
  eeoClassificationSubtext: `Esto se puede actualizar en el perfil del empleado.`,
  eeoDefaultClassification: `EEO-1 Class {{ id }} ({{ name }})`,
  eeoTitle: `Clasificación EEO-1`,
  eeoHelperText: `Asignación de categoría de trabajo de la Comisión de Igualdad de Oportunidades en el Empleo (EEOC) utilizada en los informes EEO-1`,
  eeoExecutiveSeniorLevelOfficialsAndManagers: `Funcionarios y gerentes de nivel ejecutivo / superior`,
  eeoFirstMidLevelOfficialsAndManagers: `Funcionarios y Gerentes de Nivel Primero/Medio`,
  eeoProfessionals: `Profesionales`,
  eeoTechnicians: `Técnicos`,
  eeoSalesWorkers: `Trabajadores de ventas`,
  eeoAdministrativeSupportWorkers: `Trabajadores de apoyo administrativo`,
  eeoCraftWorkers: `Los artesanos`,
  eeoOperatives: `Operativos`,
  eeoLaborersAndHelpers: `Obreros y ayudantes`,
  eeoServiceWorkers: `Trabajadores de servicios`,
  eeoNone: `-- NINGUNO --`,
  email: `Correo electrónico`,
  emailAddress: `Dirección de correo electrónico`,
  emailAlert: `Proporcione una dirección de correo electrónico para que su empleado use la aplicación Toast Teams para chatear con su equipo, ver los detalles de su turno y ver su resumen de pago.`,
  emailAddressInvalid: `Por favor ingrese su dirección de correo electrónico válida`,
  emailAddressRequired: `Se requiere Dirección de correo electrónico`,
  emailAddressSubtext: `¿No tienes una dirección de correo electrónico?`,
  emailAddressErrorSubtext: `Proporcione una dirección de correo electrónico`,
  emailAlreadyInUse: `Esta dirección de correo electrónico ya la utiliza otro empleado`,
  employeeAddedEmailSubtext: `Se envió un correo electrónico de bienvenida a {{ name }} con tareas de incorporación para completar su perfil.`,
  employeeAddedHeader: `{{ name }} fue agregado con éxito`,
  employeeAddedNoEmailSubtext: `Agregue un correo electrónico más tarde para que {{ name }} pueda comenzar su proceso de incorporación.`,
  employeeOnboarding: `Incorporación de empleados`,
  employeeOnboardingSubtext: `Los pasos que completará su empleado cuando inicie sesión por primera vez en Toast Payroll`,
  employeeDetails: `Detalles del empleado`,
  employeeIdAlreadyTaken: `¡La identificación de empleado que ingresó ya estaba en uso! Genere una identificación disponible arriba.`,
  employeeNumber: `Número de identificación del empleado`,
  employeeNumberAlreadyInUse: `Este número de ID de empleado ya está en uso`,
  employeeNumberRegexFailed: `El número de empleado debe tener entre 4 y 8 dígitos`,
  employeeNumberRequired: `Número de empleado es requerido`,
  employeeNumberSubtext: `Autocompletado con el siguiente ID de empleado disponible`,
  employees: `Empleados`,
  employeesPerPage: `Empleados por página`,
  employeeW2: `Empleado (W-2)`,
  exitingAddEmployeeBody: `Salir ahora detendrá su progreso y no se guardará.`,
  exitingAddEmployeeHeader: `Vaya, este empleado aún no ha sido agregado.`,
  fieldRequired: `Este campo es obligatorio`,
  fillOutI9: `Complete el I-9 federal`,
  fillOutI9Subtext: `Requerido para comenzar a trabajar`,
  fillOutW4: `Complete el W-4 federal y estatal`,
  fillOutW4Subtext: `Documentos fiscales requeridos`,
  filter: `Filtrar`,
  filters: `Filtros`,
  firstName: `Nombre de pila`,
  firstNameRegexFailed: `El nombre no puede incluir emojis ni símbolos especiales`,
  firstNameRequired: `Se requiere el primer nombre`,
  generalJobInfo: `Información general del trabajo`,
  genericErrorMessage: `Por favor complete todos los campos requeridos antes de continuar`,
  here: `aquí.`,
  hideDocuments: `Ocultar documentos`,
  hourAbbr: `h`,
  hourly: `Cada hora`,
  hourlyPay: `Pago por hora`,
  hourlyHelpText: 'Pago por hora para todos los trabajos',
  hourlyOvertimeHelperText: `Los empleados por horas deben ser elegibles para ganar horas extras`,
  howEmployeeTaxed: `¿Qué tipo de miembro del equipo está agregando?`,
  howEmployeePaid: `¿Cómo se le pagará a {{ name }}?`,
  isOvertimeEligible: `Is {{ name }} eligible to earn overtime?`,
  itinNotAccepted: `No se aceptan Números de Identificación Individual del Contribuyente (ITIN). Ingrese un SSN válido.`,
  job: `Trabajo`,
  jobsAndPay: `Trabajos y paga`,
  lastName: `Apellido`,
  lastNameRegexFailed: `El apellido no puede incluir emojis ni símbolos especiales`,
  lastNameRequired: `Se requiere apellido`,
  learnMoreDocuments: `¿Cómo agregar un documento?`,
  learnMoreOnboarding: `Obtenga más información sobre la incorporación de nuevos empleados`,
  lengthOfEmployment: `Duración del servicio`,
  location: `Ubicación`,
  mobilePhoneNumber: `Número de teléfono móvil`,
  modifyOnboardingDocuments: `Modificar los documentos de incorporación`,
  myEmployees: `Mis empleados`,
  name: `Nombre`,
  networkEror: `La información que intenta ingresar ya la utiliza otro empleado. Para agregar este empleado, actualice lo siguiente: `,
  no: `No`,
  no1099Available: `Su empresa no tiene códigos de ganancias 1099. Para crear un miembro del equipo 1099, comuníquese con atención`,
  negativeWageError: `El salario de los empleados debe ser un número positivo`,
  noDocumentsOld: `No hay documentos guardados en Toast Payroll. Agregue documentos para ayudar a los nuevos miembros de su equipo a incorporarse.`,
  noDocuments: `No se ha agregado ningún documento. Añada documentos de incorporación de Toast para ayudar a {{ name }} a incorporarse.`,
  noDocumentsOnboarding: `No hay documentos guardados en Toast Payroll`,
  numberSelected: `{{ number }} seleccionados`,
  onboarding: `Incorporación`,
  onboardingDocuments: `Documentos de incorporación`,
  onboardingDocumentsSubHeaderOld: `A {{ name }} se le asignará revisar estos documentos durante su incorporación. Puedes agregar más documentos para todos los nuevos empleados en la configuración de documentos en Toast Payroll.`,
  onboardingDocumentsSubHeaderModal: `Ver todos los documentos de nueva contratación disponibles para el puesto de {{ name }}.`,
  onboardingDocumentsSubHeader: `A {{ name }} se le asignará revisar estos documentos durante su incorporación. Puede agregar más modificando sus documentos de incorporación.`,
  onboardingTasks: `Tareas de incorporación`,
  onboardingTasksSubHeader: `Estas tareas son necesarias para que cada empleado las complete durante la incorporación en Toast Payroll.`,
  overtimeEligibleHelpText: 'Será un empleado no exento',
  overtimeIneligibleHelpText: 'Serán clasificados como empleados exentos.',
  overtime1099HelperText: `Los contratistas independientes no están sujetos a requisitos de horas extras`,
  overtimeYes: 'Sí, será un empleado no exento',
  overtimeNo: 'No, será un empleado exento',
  payGroup: `Grupo de Pago`,
  payGroupLocked: `A un empleado solo se le puede pagar de un grupo de pago por ubicación`,
  payType: `Tipo de Pago`,
  paymentMethod: `Método de pago`,
  permissions: `Permisos`,
  personalInformation: `Información personal (dirección, información de contacto)`,
  phoneNumber: `Número de teléfono`,
  phoneNumberInvalid: `El número de teléfono no es válido`,
  posAccessCode: `Código de acceso al POS`,
  posAccessCodeAlreadyInUse: `Este código de acceso al POS ya está en uso`,
  posAccessCodeMightBeInUse: `No podemos validar este código de acceso, comuníquese con atención`,
  posAccessCodeRegexFailed: `El código de acceso al POS debe tener entre 4 y 8 dígitos`,
  posAccessCodeRequired: `Se requiere código de acceso POS`,
  posAccessCodeSubtext: `Esto está predeterminado en la identificación del empleado, pero puede seleccionar un nuevo código de acceso de 4-8 dígitos`,
  position: `Posición`,
  positions: `Posiciones`,
  positionHelper: `La posición conecta a un empleado con un grupo de pago y es un título utilizado en ciertos informes de nómina.`,
  positionLocked: `Un empleado solo puede tener un puesto por grupo salarial`,
  rehireTeamMember: `Recontratar miembro del equipo`,
  reportEmployeeIncident: `Reportar incidente de empleado`,
  returnToTeamPage: `Volver a la página del equipo`,
  revertToOriginal: `Revertir a original`,
  review: `Revisar`,
  reviewDocuments: `Revisar documentos`,
  salary: `Salario`,
  salaryHelpText: 'Pagar un salario anual para todos los trabajos',
  salaryPerPay: 'Salario por periodo de pago',
  save: 'Guardar',
  securityHelperText: `Esto otorga permiso para Nómina. Los permisos de POS se asignarán automáticamente en función de los trabajos del empleado`,
  securityRole: `Rol de seguridad de nómina`,
  selectACA: `Seleccione un plan de ley del Cuidado de Salud a Bajo Precio`,
  selectAll: `Seleccionar todo`,
  selectDocument: `Seleccionar documento`,
  selectBenefitPlan: `Seleccione un plan de beneficios`,
  selectLocations: `seleccionar ubicaciones`,
  selectOnboardingDocuments: `Seleccionar documentos de embarque`,
  selectPaymentMethod: `Seleccione el método de pago`,
  selectPaymentMethodSubtext: `Elija cómo recibir el pago`,
  selectPositions: `Seleccionar posiciones`,
  selectStatuses: `Seleccionar estados`,
  selectTAFW: `Seleccione una política de tiempo fuera del trabajo`,
  selectWLI: `Seleccione una opción de Trabajo e Industrias de Washington`,
  selectWorkersComp: `Seleccione una opción de compensación para trabajadores`,
  singlePayGroup: `Solo hay un grupo de pago`,
  ssn: `Número de seguro social`,
  socialSecurityNumber: `número de Seguro Social (SSN)`,
  socialSecurityNumberAlreadyInUse: `Este SSN ya está en uso por otro empleado`,
  socialSecurityNumberAlert: `Toast Payroll no recopila los SSN de ni otros tipos de números de identificación de contribuyente individual (ITIN) para contratistas 1099.`,
  socialSecurityNumberNotProvided: `Agregue un SSN para evitar cargos al declarar impuestos`,
  socialSecurityNumberRegexFailed: `Ingrese un SSN válido.`,
  socialSecurityNumberRequired: `Se requiere número de seguro social`,
  socialSecurityNumberSubtext: `Los SSN se utilizan para la declaración de impuestos y deben ser precisos. Esto puede ser completado más tarde por usted o el empleado.`,
  socialSecurityUsage: `Uso del número de seguro social`,
  socialSecurityUsageSubtext: `¿El uso de este número de seguro social es por motivos comerciales?`,
  somethingIsWrong: `Algo está mal`,
  somethingIsWrongSubtext: `No pudimos salvar a este empleado. Inténtalo de nuevo`,
  startDate: `Fecha de contratación`,
  startDateSixMonths: `Elija una fecha dentro de los próximos 6 meses`,
  startDateSubtext: `Esta es la primera fecha en que su empleado será elegible para recibir pago y/o, si corresponde, beneficios`,
  status: `Estado`,
  submit: `Enviar`,
  successfullyCopied: `Copiado correctamente al portapapeles`,
  taxationTypeRequired: `Este campo es obligatorio`,
  team: `Equipo`,
  teamActions: `Acciones de equipo`,
  tellUsAboutThisTeamMember: `Cuéntanos sobre este miembro del equipo`,
  tafwPolicy: `Política de tiempo fuera del trabajo`,
  today: `Hoy`,
  training: `Capacitación`,
  tryAgain: `Intentar otra vez`,
  uploadFileHelperText: `Tipo de archivo PDF`,
  viewDocuments: `Ver documentos`,
  viewEmployeeProfile: `Ver el perfil de empleado de {{ name }}`,
  w4TaxDocument: `Documento fiscal W-4`,
  willYouProvideEmail: `¿Proveerás una dirección de correo electrónico?`,
  willYouProvideEmailSubtext: `
  Agregue la dirección de correo electrónico de su empleado para que pueda iniciar sesión en Toast Payroll, autoincorporarse, recibir formularios de impuestos electrónicos y obtener acceso exclusivo al piloto de la aplicación para empleados de Toast Teams.`,
  willYouProvideSsn: `¿Ingresará un número de Seguro Social (SSN) ahora?`,
  willYouProvideSsnSubtext: `El número de seguro social es usado para la declaración de impuestos y deben ser precisos`,
  willProvideYesSsnToggle: `Sí, lo tengo`,
  willProvideNoSsnToggle: `No, mi empleado o equipo lo entrará luego`,
  wli: `Industrias y trabajo de Washington`,
  workersComp: `Compensación de trabajadores`,
  workTaxLocation: `Ubicación impuesto del trabajo`,
  workTaxLocationLocked: `Un empleado solo puede tener una ubicación de impuestos laborales por grupo de pago`,
  yearAbbr: `en`,
  yes: `Sí`,
  pleaseRetry: 'Vuelve a cargar la página e inténtalo de nuevo.',
  hmmSomethingWentWrong: "Hmm... No pudimos cargar esta página'.",
  somethingWentWrongRetry: `Algo está mal, por favor vuelve a cargar la página e inténtalo de nuevo.`
}

export { translations }
