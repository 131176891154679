//

const translations = {
  amountTooLarge: 'Amount is too large',
  aboutThisEmployee: `About this employee`,
  acaPlan: `Affordable Care Act (ACA) plan`,
  actions: `Actions`,
  add: `Add`,
  addEmployee: `Add to team`,
  addEmail: `+ Add email`,
  addEmailOnboarding: `Add an email`,
  addJob: `Add job`,
  addTeamMember: `Add team member`,
  additionalDocuments: `Additional documents`,
  additionalDocumentsSubtext: `Upload additional documents specifically for {{ name }}'s onboarding. These files will only be applied once and will not be used for other new hires.`,
  additionalDocumentsUploadError: `The following documents could not be uploaded, please remove them and try again.`,
  apply: `Apply`,
  assignOnboardingDocument: `Assign onboarding documents`,
  back: `Back`,
  basicInfo: `Basic info`,
  benefitPlan: `Benefit plan`,
  benefits: `Benefits`,
  bulkPayChange: `Bulk pay change`,
  business: `Business (FEIN)`,
  businesses: `Businesses (FEIN)`,
  cancel: `Cancel`,
  cantReviewDocsNoEmailWarning: `{{ name }} won't be able review these documents until an email is provided. `,
  cantReviewDocsNoEmailWarningCont: ` to ensure they can create their account and finish onboarding.`,
  checkOutThisArticle: `check out this article.`,
  chosenName: `Chosen name`,
  chosenNameRegexFailed: `Chosen name cannot include emojis`,
  clear: `Clear`,
  clearFilters: `Clear filters`,
  completePayrollAccount: `Complete payroll account`,
  completePayrollAccountSubtext: `Create payroll login`,
  completePersonalInformation: `Complete personal information`,
  completePersonalInformationSubtext: `Address, contact information, etc.`,
  continue: `Continue`,
  contractor1099: `Contractor (1099)`,
  customFields: `Custom fields`,
  customFieldRequired: `This custom field is required`,
  customFieldsSubtext: `The information you provide will be displayed in {{ name }}'s onboarding documents.`,
  deleteJob: `Remove job`,
  desktopFactWelcomeHeader: `Welcome to New Hire!`,
  desktopFactWelcomeDescription: `Add your employees here! We updated this form to make things easier for you. For more information `,
  desktopFactRequestSsnHeader: `Requiring SSN`,
  desktopFactDontRequireSsnDescription: `Your company marks SSNs as optional for team members at this time. You can manage this in `,
  desktopFactRequireSsnDescription: `Your company is currently set to require SSNs for all team members before a record can be created. Administrator can manage this in `,
  desktopFactNewHireSettings: `New Hire Settings.`,
  desktopFactNewHireSettingsInQuotes: `"New Hire Settings."`,
  desktopFactTippedEmployeeHeader: `Tipped Employees`,
  desktopFactTippedEmployeeDescription: `For tipped employees, enter the base pay. For more information on how to manage and integrate tips, `,
  desktopFactEEOHeader: `EEO-1 Classification`,
  desktopFactEEODescription: `Your company is currently set to collect EEO information on employees. If you don't need this step, you can simply turn it off in `,
  desktopFactEEODescriptionNonAdmin: `Your company is currently set to collect EEO information on employees. Administrators can manage this in “New Hire Settings.”`,
  desktopFactPermissionsHeader: `Permissions`,
  desktopFactPermissionsDescription: `This determines what access employees have within the Toast Payroll system. The role with the lowest access is "Employee." For more information about security roles, `,
  desktopFactBenefitsHeader: `Benefits & More for New Payroll Users`,
  desktopFactBenefitsDescription: `If Time Away from Work and other policies have yet to be set up, select "default" or "TBD" or similar from the drop-down. If you're still working with your onboarding consultant, contact them for assistance. For more help, reach out to `,
  desktopFactDocumentsHeader: `Add onboarding documents for all new hires`,
  desktopFactDocumentsDescription: `Administrators can add documents for future team members to review `,
  desktopFactDocumentsHereLink: `here`,
  desktopFactDocumentsRest: `, so you don't have to upload them each time.`,
  desktopFactDocumentNonAdmin: `in the “Required Documentation Settings”`,
  desktopFactReviewLastStepHeader: `Last step!`,
  desktopFactReviewLastStepDescription: `This is how your employee will be set at the time of new hire. Clicking "Add to team" will create their record. You can always update their information directly on their profile.`,
  desktopFactReviewNextHeader: `What happens next?`,
  desktopFactReviewNextDescription: `After you click "Add to team" below, if you've provided an email address for your employee, they'll receive a welcome email from us to start onboarding. You can review what their onboarding experience is in this `,
  desktopFactReviewNextArticle: `article`,
  discardExit: `Discard & exit`,
  documents: `Documents`,
  dragAndDropText: `or drag and drop file here`,
  eeoClassification: `EEO-1 Job category`,
  eeoClassificationSubtext: `This can be updated in the employee profile`,
  eeoDefaultClassification: `EEO Class {{ id }} ({{ name }})`,
  eeoTitle: `EEO-1 Classification`,
  eeoHelperText: `Equal Employment Opportunity Commission (EEOC) Job Category assignment used in EEO-1 Reporting`,
  eeoExecutiveSeniorLevelOfficialsAndManagers: `Executive/Senior Level Officials and Managers`,
  eeoFirstMidLevelOfficialsAndManagers: `First/Mid Level Officials and Managers`,
  eeoProfessionals: `Professionals`,
  eeoTechnicians: `Technicians`,
  eeoSalesWorkers: `Sales Workers`,
  eeoAdministrativeSupportWorkers: `Administrative Support Workers`,
  eeoCraftWorkers: `Craft Workers`,
  eeoOperatives: `Operatives`,
  eeoLaborersAndHelpers: `Laborers and Helpers`,
  eeoServiceWorkers: `Service Workers`,
  eeoNone: `-- NONE --`,
  email: `Email`,
  emailAddress: `Email address`,
  emailAlert: `Provide an email address for your employee to use the Toast Teams app to chat with their team, view their shift details, and see their pay summary.`,
  employmentDetails: 'Employment details',
  taxDesignation: 'Tax designation',
  emailAddressInvalid: `Please provide a valid email address`,
  emailAddressRequired: `Email address is required`,
  emailAddressSubtext: `Don't have an email address?`,
  emailAddressErrorSubtext: `Please provide an email address`,
  emailAlreadyInUse: `This email address is already used by another employee`,
  employeeAddedEmailSubtext: `A welcome email was sent to {{ name }} with onboarding tasks to complete their profile.`,
  employeeAddedHeader: `{{ name }} was successfully added`,
  employeeAddedNoEmailSubtext: `Add an email later so {{ name }} can start their onboarding process.`,
  employeeDetails: `Employee details`,
  employeeIdAlreadyTaken: `The employee ID you entered was already in use! Generate an available ID above.`,
  employeeNumber: `Employee ID number`,
  employeeNumberAlreadyInUse: `This employee ID number is already in use`,
  employeeNumberRegexFailed: `Employee number must be 4-8 digits`,
  employeeNumberRequired: `Employee number is required`,
  employeeNumberSubtext: `Autofilled with the next available employee ID`,
  employeeOnboarding: `Employee onboarding`,
  employeeOnboardingSubtext: `This is what your employee will do when they first log in to Toast Payroll`,
  employees: `Employees`,
  employeesPerPage: `Employees per page`,
  employeeW2: `Employee (W-2)`,
  exitingAddEmployeeBody: `Exiting now will stop your progress and it will not be saved.`,
  exitingAddEmployeeHeader: `Woah there, this employee has not been added yet`,
  fieldRequired: `This field is required`,
  fillOutI9: `Fill out federal I-9`,
  fillOutI9Subtext: `Required to begin working`,
  fillOutW4: `Fill out federal and state W-4`,
  fillOutW4Subtext: `Required tax documents`,
  filter: `Filter`,
  filters: `Filters`,
  firstName: `First name`,
  firstNameRegexFailed: `First name cannot include emojis or special symbols`,
  firstNameRequired: `First name is required`,
  generalJobInfo: `General job information`,
  genericErrorMessage: `Please fill in all required fields before continuing`,
  here: `here.`,
  hideDocuments: `Hide documents`,
  hourAbbr: `hr`,
  hourly: `Hourly`,
  hourlyPay: `Hourly pay`,
  hourlyHelpText: 'Pay an hourly rate for all jobs',
  hourlyOvertimeHelperText: `Hourly employees must be eligible to earn overtime`,
  howEmployeeTaxed: `What type of team member are you adding?`,
  howEmployeePaid: `How will {{ name }} be paid?`,
  isOvertimeEligible: `Is {{ name }} eligible to earn overtime?`,
  itinNotAccepted: `Individual Taxpayer Identification Numbers (ITIN) are not accepted. Please enter a valid SSN.`,
  job: `Job`,
  jobsAndPay: `Jobs & pay`,
  lastName: `Last name`,
  lastNameRegexFailed: `Last name cannot include emojis or special symbols`,
  lastNameRequired: `Last name is required`,
  learnMoreDocuments: `How to add a document`,
  learnMoreOnboarding: `Learn more about new hire onboarding`,
  lengthOfEmployment: `Length of employment`,
  location: `Location`,
  mobilePhoneNumber: `Mobile phone number`,
  modifyOnboardingDocuments: `Modify onboarding documents`,
  myEmployees: `My Employees`,
  name: `Name`,
  networkEror: `The information you're trying to enter is already used by another employee. To add this employee, update the following: `,
  no: `No`,
  no1099Available: `Your company has no 1099 earning codes. To create a 1099 team member contact care`,
  negativeWageError: `Employee's pay must be a positive number`,
  //todo: after ff is fully elevated delete noDocumentsOld translation
  noDocumentsOld: `There are no documents saved in Toast Payroll. Add documents to help your new team members onboard.`,
  noDocuments: `There are no default documents added. Assign onboarding documents from Toast to help {{ name }} onboard.`,
  noDocumentsOnboarding: `No documents saved in Toast Payroll`,
  numberSelected: `{{ number }} selected`,
  onboarding: `Onboarding`,
  onboardingDocuments: `Onboarding documents`,
  //todo: after ff is fully elevated delete onboardingDocumentsSubHeaderOld translation
  onboardingDocumentsSubHeaderOld: `{{ name }} will review these documents during their onboarding. You can add more documents for all new hires in your document settings in Toast Payroll.`,
  onboardingDocumentsSubHeaderModal: `Browse all new hire documents available for {{ name }}'s position.`,
  onboardingDocumentsSubHeader: `{{ name }} will review these documents during their onboarding. You can add more by modifying their onboarding documents.`,
  onboardingTasks: `Onboarding tasks`,
  onboardingTasksSubHeader: `These tasks are required for each employee to complete during onboarding in Toast Payroll.`,
  overtimeEligibleHelpText: `They'll be a non-exempt employee`,
  overtimeIneligibleHelpText: `They'll be an exempt employee`,
  overtime1099HelperText: `Independent contractors are not subject to overtime requirements`,
  overtimeYes: 'Yes, they are non-exempt',
  overtimeNo: 'No, they are exempt',
  payGroup: `Pay group`,
  payGroupLocked: `An employee can only be paid out of one pay group per location`,
  payType: `Pay type`,
  paymentMethod: `Payment method`,
  permissions: `Permissions`,
  personalInformation: `Personal information (address, contact information)`,
  phoneNumber: `Phone number`,
  phoneNumberInvalid: `Phone number is not valid`,
  posAccessCode: `POS access code`,
  posAccessCodeAlreadyInUse: `This POS access code is already in use`,
  posAccessCodeMightBeInUse: `We are unable to validate this access code, please contact care`,
  posAccessCodeRegexFailed: `POS access code must be 4-8 digits`,
  posAccessCodeRequired: `POS access code is required`,
  posAccessCodeSubtext: `This is defaulted to the employee ID but you can select a new 4-8 digit passcode`,
  position: `Position`,
  positions: `Positions`,
  positionHelper: `Position connects an employee to a Pay Group, and is a title used in certain Payroll reports`,
  positionLocked: `An employee can only have one position per pay group`,
  rehireTeamMember: `Rehire Team Member`,
  reportEmployeeIncident: `Report employee incident`,
  returnToTeamPage: `Return to Team page`,
  revertToOriginal: `Revert to original`,
  review: `Review`,
  reviewDocuments: `Review documents`,
  salary: `Salary`,
  salaryHelpText: 'Pay an annual salary for all jobs',
  salaryPerPay: 'Salary per pay period',
  save: `Save`,
  securityHelperText: `This grants permission for Payroll. POS permissions will be automatically assigned based on the employee's jobs`,
  securityRole: `Payroll security role`,
  selectACA: `Select an affordable care act plan`,
  selectAll: `Select all`,
  selectDocument: `Select document`,
  selectBenefitPlan: `Select a benefit plan`,
  selectLocations: `Select Locations`,
  selectOnboardingDocuments: `Select onboarding documents`,
  selectPaymentMethod: `Select payment method`,
  selectPaymentMethodSubtext: `Choose how to receive payment`,
  selectPositions: `Select positions`,
  selectStatuses: `Select statuses`,
  selectTAFW: `Select a time away from work policy`,
  selectWLI: `Select a Washington Labor & Industries option`,
  singlePayGroup: `There is only one available pay group`,
  selectWorkersComp: `Select a workers compensation option`,
  ssn: `Social Security number`,
  socialSecurityNumber: `Social Security number (SSN)`,
  socialSecurityNumberAlreadyInUse: `This SSN is already in use by another employee`,
  socialSecurityNumberAlert: `Toast Payroll does not collect SSNs or other types of Individual Taxpayer Identification Numbers (ITIN) for 1099 contractors.`,
  socialSecurityNumberNotProvided: `Add a SSN to avoid fees when filing taxes`,
  socialSecurityNumberRegexFailed: `Please enter a valid SSN.`,
  socialSecurityNumberRequired: `Social security number is required`,
  socialSecurityNumberSubtext: `SSN's are used for tax filing, and must be accurate. This can be completed later by you or the employee.`,
  socialSecurityUsage: `Social security usage`,
  socialSecurityUsageSubtext: `Is the usage of this social security number for business reasons?`,
  somethingIsWrong: `Something is wrong`,
  somethingIsWrongSubtext: `We were unable to save this employee. Please try again`,
  startDate: `Start date`,
  startDateSixMonths: `Please choose a date within the next 6 months`,
  startDateSubtext: `This is the first date your employee will be eligible for pay and/or, if applicable, benefits`,
  status: `Status`,
  submit: `Submit`,
  successfullyCopied: `Successfully copied to clipboard`,
  taxationTypeRequired: `This field is required`,
  team: `Team`,
  teamActions: `Team Actions`,
  tellUsAboutThisTeamMember: `Tell us about this team member`,
  tafwPolicy: `Time away from work policy`,
  today: `Today`,
  training: `Training`,
  tryAgain: `Try again`,
  uploadFileHelperText: `File type PDF`,
  viewDocuments: `View documents`,
  viewEmployeeProfile: `View {{ name }}'s employee profile`,
  w4TaxDocument: `W-4 tax document`,
  willYouProvideEmail: `Will you provide an email address?`,
  willYouProvideEmailSubtext: `Add your employee's email address so they can log in to Toast Payroll, self-onboard, receive electronic tax forms and get exclusive access to the pilot of the Toast Teams employee app.`,
  willYouProvideSsn: `Will you enter a Social Security number (SSN) now?`,
  willYouProvideSsnSubtext: `SSN's are used for tax filing and must be accurate`,
  willProvideYesSsnToggle: `Yes, I have it`,
  willProvideNoSsnToggle: `No, it will be entered later`,
  wli: `Washington Labor & Industries`,
  workersComp: `Workers compensation`,
  workTaxLocation: `Work tax location`,
  workTaxLocationLocked: `An employee can only have one work tax location per pay group`,
  yearAbbr: `yr`,
  yes: `Yes`,
  pleaseRetry: 'Please reload the page and try again.',
  hmmSomethingWentWrong: `Hmm...We couldn't load this page.`,
  somethingWentWrongRetry: `Something went wrong, please reload and try again.`
}

type Translations = typeof translations

export type { Translations }

export { translations }
